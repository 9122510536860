
import { IDunningScenarioForm } from 'apps/middle/src/app/dunning-settings-module/models';
import { IMiddleTextEditorTemplate } from 'apps/middle/src/app/shared-module/models';

import { IStoreEntityStateSlice, createEntitySlice } from '../functions';

export interface IDunningScenarioDetailState {
	isLoading: boolean; // TODO: remove isLoading and error if unused
	error: Error;
	stepError: any;
	templates: IStoreEntityStateSlice<IMiddleTextEditorTemplate[]>;
	formModel: IStoreEntityStateSlice<IDunningScenarioForm>;
	selectedStep: number;
	stepFormIsDirty: boolean;
}

export const initialState: IDunningScenarioDetailState = {
	isLoading: false,
	error: null,
	stepError: null,
	templates: createEntitySlice({entity: []}),
	formModel: createEntitySlice(),
	selectedStep: 0,
	stepFormIsDirty: false,
};
