/* tslint:disable:indent */

import { createReducer, on } from '@ngrx/store';
import { DunningInterlocutorTypes } from 'apps/middle/src/app/dunnings-module/enums';
import { DunningDuePosition } from 'apps/middle/src/app/dunning-settings-module/enums';
import { sortSteps } from 'apps/middle/src/app/dunning-settings-module/functions';
import { IScenarioStep } from 'apps/middle/src/app/dunning-settings-module/models';

import { withEntityReducer } from '../functions';

import { IDunningScenarioDetailState, initialState } from './state';
import * as featureActions from './actions';

export const DunningScenarioDetailReducer = createReducer<IDunningScenarioDetailState>(
	initialState,

	...withEntityReducer<IDunningScenarioDetailState>('formModel', featureActions.formModelActions),

	on(featureActions.InitRequest, _ => ( {
		...initialState,
		error: null,
		isLoading: true,
	} )),
	on(
		featureActions.SaveFormModelSuccess,
		(state, action) => {
			return {
				...state,
				formModel: {
					...state.formModel,
					entity: {
						...state.formModel.entity,
						id: action.formModel.id
					}
				}
			};
		}),
	on(
		featureActions.UpdateFormModelSuccess,
		state => {
			return {
				...state,
				formModel: {
					...state.formModel,
					entity: {
						...state.formModel.entity,
					}
				}
			};
		}),
	on(
		featureActions.InitSuccess,
		featureActions.DeleteCanceled,
		featureActions.DeleteConfirmSuccess,
		state => ( {
			...state,
			error: null,
			isLoading: false,
		} )),

	on(
		featureActions.InitFailure,
		featureActions.AddScenarioStepFailure,
		(state, action) => ( {
			...state,
			error: action.error,
			isLoading: false
		} )),

	on(
		featureActions.UpdateScenarioStepRequest,
		state => ( {
			...state,
			isLoading: true
		} )),

	on(
		featureActions.UpdateScenarioStepFailure,
		(state, action) => ( {
			...state,
			isLoading: false,
			stepError: action.error,
		} )),

	on(
		featureActions.LoadFormModelRequest,
		featureActions.DeleteEntityRequest,
		featureActions.DeleteFormModelConfirm,
		state => ( {
			...state,
			error: null,
			isLoading: true,
		} )),

	on(
		featureActions.SelectScenarioStep,
		(state, action) => {
			return {
				...state,
				selectedStep: action.param,
				stepError: action.param === state.selectedStep
					? state.stepError
					: null
			};
		}),

	on(
		featureActions.AddScenarioStep,
		(state, action) => {
			let step = action.step;

			// When creating a step from scratch
			if (!step) {
				step = newStep(state);
			}

			const entity = {
				...state.formModel.entity,
				...action.formModel,
				steps: sortSteps([...state.formModel.entity.steps, step])
			};

			return {
				...state,
				formModel: {
					...state.formModel,
					entity
				},
				stepError: null,
				selectedStep: entity.steps.indexOf(step)
			};
		}),

	on(
		featureActions.UpdateScenarioStepSuccess,
		(state, action) => {
			const steps = sortSteps(state.formModel.entity.steps
				.filter(s => !!s.id && s.id !== action.entity.id)
				.concat(action.entity));

			return {
				...state,
				isLoading: false,
				formModel: {
					...state.formModel,
					entity: {
						...state.formModel.entity,
						steps: steps
					}
				},
				selectedStep: steps.indexOf(action.entity)
			};
		}),

	on(
		featureActions.DeleteScenarioStepSuccess,
		(state, action) => {
			return {
				...state,
				formModel: {
					...state.formModel,
					entity: {
						...state.formModel.entity,
						steps: state.formModel.entity.steps.filter(s => s !== action.entity)
					}
				}
			};
		}),

	on(
		featureActions.MarkStepFormAsClean,
		featureActions.MarkStepFormAsDirty,
		(state, action) => {
			return {
				...state,
				stepFormIsDirty: action.type === featureActions.MarkStepFormAsDirty.type
			};
		}),
);

function newStep(state: IDunningScenarioDetailState): IScenarioStep {
	const maxDays = maxDaysFromSteps(state.formModel.entity.steps);
	const days = Math.min(maxDays + 10, maxDays + Math.ceil((1000 - maxDays) / 2));
	return {
		due: DunningDuePosition.After,
		type: null,
		subType: null,
		days,
		interlocutorType: DunningInterlocutorTypes.SuperDebtorContact
	};
}

export function maxDaysFromSteps(steps: IScenarioStep[]) {
	return steps.reduce((acc, step) => ( step.due === DunningDuePosition.After && step.days > acc ? step.days : acc ), 0);
}
