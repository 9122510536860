import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { createEntitySliceSelectors } from '../functions';

import { IDunningTemplateDetailState } from './state';
import { FeatureName } from './feature';



export const selectState: MemoizedSelector<object, IDunningTemplateDetailState> = createFeatureSelector<IDunningTemplateDetailState>(FeatureName);

const getIsLoading = (state: IDunningTemplateDetailState): boolean => state.isLoading;
const getError = (state: IDunningTemplateDetailState): any => state.error;
const getDeleteError = (state: IDunningTemplateDetailState): any => state.deleteError;
const getDeleteIsLoading = (state: IDunningTemplateDetailState): boolean => state.deleteIsLoading;

export const {
	selectEntity: selectFormModel,
	selectError: selectFormModelError,
	selectIsLoading: selectFormModelIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.formModel));

export const selectIsLoading = createSelector(selectState, getIsLoading);
export const selectError = createSelector(selectState, getError);
export const selectDeleteError = createSelector(selectState, getDeleteError);
export const selectDeleteIsLoading = createSelector(selectState, getDeleteIsLoading);

