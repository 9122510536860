
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FeatureName } from './feature';
import { DunningScenarioDetailReducer } from './reducer';
import { DunningScenarioDetailStoreEffects } from './effects';


@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(FeatureName, DunningScenarioDetailReducer),
		EffectsModule.forFeature([DunningScenarioDetailStoreEffects])
	]
})

export class DunningScenarioDetailStoreModule {
}
