import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { createEntitySliceSelectors } from '../functions';

import { IDunningScenarioDetailState } from './state';
import { FeatureName } from './feature';



export const selectState: MemoizedSelector<object, IDunningScenarioDetailState> = createFeatureSelector<IDunningScenarioDetailState>(FeatureName);

const getError = (state: IDunningScenarioDetailState): any => state.error;
const getStepError = (state: IDunningScenarioDetailState): any => state.stepError;
const getIsLoading = (state: IDunningScenarioDetailState): boolean => state.isLoading;

const getSelectedStep = (state: IDunningScenarioDetailState): number => state.selectedStep;
const getStepFormIsDirty = (state: IDunningScenarioDetailState): boolean => state.stepFormIsDirty;

export const selectError = createSelector(selectState, getError);
export const selectStepError = createSelector(selectState, getStepError);
export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectSelectedStep = createSelector(selectState, getSelectedStep);
export const selectStepFormIsDirty = createSelector(selectState, getStepFormIsDirty);

export const {
	selectEntity: selectFormModel,
	selectError: selectFormModelError,
	selectIsLoading: selectFormModelIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.formModel));

export const {
	selectEntity: selectTemplates,
	selectError: selectTemplatesError,
	selectIsLoading: selectTemplatesIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.templates));
