import { createReducer, on } from '@ngrx/store';

import { withEntityReducer } from '../functions';

import * as featureActions from './actions';
import { initialState, IDunningTemplateDetailState } from './state';



export const DunningTemplateDetailReducer = createReducer<IDunningTemplateDetailState>(
	initialState,

	...withEntityReducer<IDunningTemplateDetailState>('formModel', featureActions.formModelActions),

	on(featureActions.InitRequest, () => ({
		...initialState,
		error: null,
		isLoading: false,
	})),

	on(featureActions.InitSuccess, state => ({
		...state,
		error: null,
		isLoading: false,
	})),

	on(featureActions.InitFailure, (state, action) => ({
		...state,
		error: action.error,
		isLoading: false
	})),
	on(featureActions.UseTemplateSuccess, (state, action) => {
			const replace = {...action.template};
			delete replace.name;

			return {
				...state,
				formModel: {
					...state.formModel,
					entity: {
						...state.formModel.entity,
						...replace,
						...action.withProps
					}
				}
			};
		}
	),
	on(featureActions.ResetLoaders, state => ({
		...state,
		isLoading: false,
		error: null,
		deleteIsLoading: false,
		deleteError: null,
		previewIsLoading: false,
		previewError: null,
	})),
);
