
import { ITextEditorClip } from '@aston/richtext-editor';
import { IMiddleTextEditorTemplate, ITemplateForm } from 'apps/middle/src/app/shared-module/models';
import { mapToTemplateDefaultFormModel } from 'apps/middle/src/app/shared-module/functions';

import { createEntitySlice, IStoreEntityStateSlice } from '../functions';

export interface IDunningTemplateDetailState {
	isLoading: boolean; // TODO: remove isLoading and error if unused
	error: Error;
	templateId: number;
	deleteIsLoading: boolean;
	deleteError: string;
	templates: IStoreEntityStateSlice<IMiddleTextEditorTemplate[]>;
	clips: IStoreEntityStateSlice<ITextEditorClip[]>;
	formModel: IStoreEntityStateSlice<ITemplateForm>;
}

export const initialState: IDunningTemplateDetailState = {
	isLoading: false,
	error: null,
	templateId: null,
	deleteIsLoading: false,
	deleteError: null,
	templates: createEntitySlice({ entity: [] }),
	clips: createEntitySlice({ entity: [] }),
	formModel: createEntitySlice({ entity: mapToTemplateDefaultFormModel() })
};
