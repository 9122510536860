/* tslint:disable:indent */

import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, iif } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom, filter, tap } from 'rxjs/operators';
import * as RoutesDefinitions from 'apps/middle/src/app/routes-definitions';
import { Router } from '@angular/router';
import { AppConstants } from 'apps/middle/src/app/app.constants';
import { ICanDeleteDunningScenarioItem, IDunningScenarioForm } from 'apps/middle/src/app/dunning-settings-module/models';
import { DunningScenarioDetailService } from 'apps/middle/src/app/dunning-settings-module/services';

import { SharedTemplatesStore } from '../../shared-module/stores';
import * as AppStoreActions from '../app-store/actions';
import { CorrelationParams, aggregate } from '../models/correlated-actions.model';

import * as featuresSelectors from './selectors';
import * as featureActions from './actions';

@Injectable({
	providedIn: 'root'
})
export class DunningScenarioDetailStoreEffects {
	constructor(
		private router: Router,
		private actions$: Actions,
		private store: Store,
		private templatesStore: SharedTemplatesStore,
		private dunningScenarioDetailService: DunningScenarioDetailService,
	) {
	}

	InitEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitRequest),
		switchMap(action => {
			const correlationParams: CorrelationParams = action.correlationParams;
			correlationParams.parentActionType = action.type;

			return [
				featureActions.LoadFormModelRequest({id: action.scenarioId, correlationParams: correlationParams}),
				AppStoreActions.Execute(() => this.templatesStore.loadAllTemplates()),
			];
		}),
		catchError(error => of(featureActions.InitFailure({error})))
	));

	InitSuccessEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitRequest),
		// Create an aggregated action listener
		// it will observe the race of all specified observable
		// and complete when all these actions are dispatched.
		// Note that individual actions workflow still apply.
		aggregate(
			this.actions$.pipe(ofType(featureActions.LoadFormModelSuccess)),
			this.actions$.pipe(ofType(featureActions.InitFailure)),
		),
		map(_ => featureActions.InitSuccess()),
		catchError(error => of(featureActions.InitFailure({error})))
	));

	loadFormModelRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadFormModelRequest),
		switchMap(action => ( action.id ?
			this.dunningScenarioDetailService.getDunningScenario(action.id) :
			this.dunningScenarioDetailService.getDunningScenarioDefault() )
		.pipe(
			switchMap(formModel => {
				const actions: any[] = [
					featureActions.LoadFormModelSuccess({
						entity: {...formModel, ...action.withDefaults}, correlationParams: action.correlationParams
					}),
				];
				actions.push(featureActions.MarkStepFormAsClean());
				return actions;
			}),
			catchError(error => of(featureActions.InitFailure({error})))
		))
	));

	saveScenarioRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateFormModelRequest),
		filter(action => !action.formModel.id),
		switchMap(action => this.dunningScenarioDetailService.createDunningScenario(action.formModel).pipe(
			switchMap(id => [
				featureActions.UpdateFormModelSuccess({formModel: {...action.formModel, id}, redirect: action.redirect}),
				featureActions.MarkStepFormAsClean(),
			]),
			catchError(error => {
					return iif(() => error.specificError && error.specificError.includes('DunningScenarioIsInGroup'),
						of(featureActions.UpdateFormModelAskDesactivateConfirm({payload: {groups: error.extraData, formModel: action.formModel}})),
						of(featureActions.UpdateFormModelFailure({error})));
				}
			)
		)
		)
	));

	createScenarioRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.SaveFormModelRequest),
		switchMap(action => this.dunningScenarioDetailService.createDunningScenario(action.formModel)
		.pipe(
			switchMap(id => [
				featureActions.SaveFormModelSuccess({formModel: {...action.formModel, id}, redirect: action.redirect}),
				// in case of a creation, reload the whole entity with created steps
				action.formModel.id ? AppStoreActions.Noop() : featureActions.LoadFormModelRequest({ id }),
				action.formModel.id ? AppStoreActions.Noop() : featureActions.InitSuccess(),
				featureActions.MarkStepFormAsClean(),
				AppStoreActions.ToastSuccess('Dunning.Scenarios.Messages.Created')
			]),
			catchError(error => of(featureActions.SaveFormModelFailure({error})))
		)
		)
	));

	updateScenarioConfirm$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateFormModelConfirm),
		map(action => AppStoreActions.OpenConfirmationModal({textsKey: `Dunning.Confirmations.UpdateScenario.`},
			featureActions.UpdateFormModelRequest({formModel: action.formModel, forceUpdate: action.forceUpdate, redirect: action.redirect})))
	));

	updateScenarioRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateFormModelRequest),
		filter(action => !!action.formModel.id),
		switchMap(action => this.dunningScenarioDetailService.updateDunningScenario(action.formModel, action.forceUpdate).pipe(
			switchMap(id => [
				featureActions.UpdateFormModelSuccess({formModel: {...action.formModel, id}, redirect: action.redirect}),
				featureActions.MarkStepFormAsClean(),
				AppStoreActions.ToastSuccess('Dunning.Scenarios.Messages.Modified')
			]),
			catchError(error => {
					return iif(() => error.specificError && error.specificError.includes('DunningScenarioIsInGroup'),
						of(featureActions.UpdateFormModelAskDesactivateConfirm({payload: {groups: error.extraData, formModel: action.formModel}})),
						of(featureActions.UpdateFormModelFailure({error})));
				}
			))
		)
	));

	askConfirmationSaveScenario$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateFormModelAskDesactivateConfirm),
		switchMap(action => of(
			AppStoreActions.OpenConfirmationModal({
				textsKey: `Dunning.Confirmations.UpdateScenarioWithRelatedGroup.`,
				questionParams: {
					data: {
						name: action.payload.formModel.name,
						groupNames: action.payload.groups.join(AppConstants.LIST_ENUM_SEP_CHAR),
						pluralVerbPresent: action.payload.groups && action.payload.groups.length > 1 ? `ent` : `e`,
						plurals: action.payload.groups && action.payload.groups.length > 1 ? `s` : ``,
						pluralVerbFuture: action.payload.groups && action.payload.groups.length > 1 ? `ont` : `a`,
					}
				}
			}, featureActions.UpdateFormModelRequest({formModel: action.payload.formModel, forceUpdate: true, redirect: true}))))
	));

	leaveFormRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LeaveFormRequest),
		map(action => action.param ?
			AppStoreActions.OpenConfirmationModal({textsKey: `Dunning.Confirmations.ScenarioFormLeave.`}, featureActions.LeaveFormSuccess()) :
			featureActions.LeaveFormSuccess()
		)
	));

	leaveFormSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LeaveFormSuccess),
		map(_ => this.router.navigate([RoutesDefinitions.getDunningScenariosListRouteFullPath()]))
	), {dispatch: false});

	navigateAfterSaveSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.SaveFormModelSuccess, featureActions.UpdateFormModelSuccess),
		filter(action => action.redirect),
		map(_ => this.router.navigate([RoutesDefinitions.getDunningScenariosListRouteFullPath()]))
	), {dispatch: false});

	addScenarioSteps$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddScenarioStepsRequest),
		withLatestFrom(this.store.select(featuresSelectors.selectFormModel)),
		switchMap(([action, formModel]) =>
			this.dunningScenarioDetailService.createDunningScenarioSteps(formModel.id,
				action.formModel.steps, formModel.type).pipe(
				switchMap(ids => [
					featureActions.AddScenarioStepsSuccess({stepIds: ids}),
					featureActions.LeaveFormSuccess()
				]),
				catchError(error => of(featureActions.AddScenarioStepFailure({error})))
			)
		)));

	addScenarioStep$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddScenarioStep),
		withLatestFrom(this.store.select(featuresSelectors.selectFormModel)),
		switchMap(([_, formModel]) => [
			featureActions.SelectScenarioStep({param: formModel.steps.length - 1}),
			featureActions.MarkStepFormAsDirty()
		])
	));

	confirmUpdateScenarioStep$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateScenarioStepConfirm),
		map(action => AppStoreActions.OpenConfirmationModal({textsKey: `Dunning.Confirmations.DeleteScenarioStep.`},
			featureActions.UpdateScenarioStepRequest({step: action.step})))
	));

	updateScenarioStep$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateScenarioStepRequest),
		withLatestFrom(this.store.select(featuresSelectors.selectFormModel)),
		switchMap(([action, formModel]) =>
			// FIXME: Update scenario step here
			( action.step.id ? this.dunningScenarioDetailService.updateDunningScenarioStep(formModel.id,
				action.step, formModel.type) : this.dunningScenarioDetailService.createDunningScenarioStep(formModel.id,
				action.step, formModel.type) ).pipe(
				switchMap(id => {
					const step = {...action.step, id};
					const actions: any[] = [
						featureActions.UpdateScenarioStepSuccess({entity: step}),
						featureActions.UpdateFormModelSuccess({formModel: {
							...formModel,
							steps: formModel.steps.map(s => s.id === id ? step : s)
						}}),
						featureActions.MarkStepFormAsClean()
					];
					if (action.thenAddStep) {
						actions.push(featureActions.AddScenarioStep({}));
					}
					return actions;
				}),
				catchError(error => of(featureActions.UpdateScenarioStepFailure({error})))
			)
		)
	));

	confirmDeleteScenarioStep$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteScenarioStepConfirm),
		map(action => AppStoreActions.OpenConfirmationModal({
				textsKey: `Dunning.Confirmations.UpdateScenario.`
			},
			featureActions.DeleteScenarioStepRequest({entity: action.entity})))
	));

	duplicateScenarioRequest$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DuplicateFormModelRequest),
		map(action => action.isFormDirty ?
			AppStoreActions.OpenConfirmationModal({textsKey: `Dunning.Confirmations.DuplicateScenario.`},
				featureActions.DuplicateFormModelSuccess({param: action.id})) :
			featureActions.DuplicateFormModelSuccess({param: action.id}))
	));

	duplicateScenarioSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DuplicateFormModelSuccess),
		map(action => this.router.navigate([RoutesDefinitions.getDunningScenarioDuplicateRouteFullPath()],
			{queryParams: {[ RoutesDefinitions.DunningScenarioDuplicateRouteParam ]: action.param}}))
	), {dispatch: false});

	deleteScenarioConfirm$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteFormModelConfirm),
		switchMap(
			action => this.dunningScenarioDetailService.askCanDeleteScenario(action.param.id)
			.pipe(
				tap(() => this.store.dispatch(( featureActions.DeleteConfirmSuccess() ))),
				switchMap((canDeleteModel) => this.createConfirmationModals(canDeleteModel, action.param)),
				catchError(error => of(featureActions.DeleteFormModelFailure({error})))
			))));

	deleteScenarioStep$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteScenarioStepRequest),
		withLatestFrom(this.store.select(featuresSelectors.selectFormModel)),
		switchMap(([action, formModel]) => (action.entity.id ?
			this.dunningScenarioDetailService.deleteDunningScenarioStep(formModel.id, action.entity) :
			of(null) )
		.pipe(
			switchMap(_ => [
				featureActions.DeleteScenarioStepSuccess({entity: action.entity}),
				featureActions.SelectScenarioStep({param: formModel.steps.length - 2}),
				featureActions.MarkStepFormAsClean()
			]),
			catchError(error => of(featureActions.DeleteScenarioStepFailure({error})))
		)
		)
	));

	deleteScenarioRequest$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteEntityRequest),
		switchMap(action => this.dunningScenarioDetailService.deleteScenario(action.entity.id).pipe(
			switchMap(() => [
				featureActions.DeleteFormModelSuccess({
					entity: action.entity, shouldRedirect: false, correlationParams: action.correlationParams
				}),
				AppStoreActions.ToastSuccess('Dunning.Scenarios.Messages.Deleted')
			]),
			catchError(error => of(featureActions.DeleteFormModelFailure({error})))
		))
	));

	deleteScenarioSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteFormModelSuccess),
		map(_ => this.router.navigate([RoutesDefinitions.getDunningScenariosListRouteFullPath()]))
	), {dispatch: false});

	createConfirmationModals(canDeleteModel: ICanDeleteDunningScenarioItem, entity: IDunningScenarioForm) { // passer les params

		return iif(
			() => canDeleteModel.canBeDeleted,
			of(AppStoreActions.OpenConfirmationModal({
				textsKey: `Dunning.Confirmations.DeleteScenarioWithNoRelatedGroup.`,
				questionParams: {data: {name: entity.name}}
			}, featureActions.DeleteEntityRequest(entity))),
			of(AppStoreActions.OpenConfirmationModal({
				textsKey: `Dunning.Confirmations.DeleteScenarioWithRelatedGroup.`,
				questionParams: {
					data: {
						name: entity.name,
						groupNames: canDeleteModel.dunningGroupsUsingIt.join(AppConstants.LIST_ENUM_SEP_CHAR),
						pluralVerbPresent: canDeleteModel.dunningGroupsUsingIt && canDeleteModel.dunningGroupsUsingIt.length > 1 ? `ent` : `e`,
						plurals: canDeleteModel.dunningGroupsUsingIt && canDeleteModel.dunningGroupsUsingIt.length > 1 ? `s` : ``,
						pluralVerbFuture: canDeleteModel.dunningGroupsUsingIt && canDeleteModel.dunningGroupsUsingIt.length > 1 ? `ont` : `a`,
					}
				}
			}, featureActions.DeleteEntityRequest(entity))));
	}
}
