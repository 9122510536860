import { createAction, props } from '@ngrx/store';
import { IMiddleTextEditorTemplate, ITemplateForm, ITemplateItem } from 'apps/middle/src/app/shared-module/models';

import { CorrelationParams, newAggregationId } from '../models/correlated-actions.model';
import { createEntityActions, createTypedAction, EntityActionType, createActionWithId, createActionWithSingleProps, createDebugAction, createErrorAction } from '../functions';



const ActionPrefix = 'Dunning Template Detail';

export const Debug = createDebugAction(`[${ActionPrefix}]`);

export const InitRequest = createAction(`[${ActionPrefix}] Init Request`,
	(templateId: number, isDuplicate = false, correlationParams: CorrelationParams = { correlationId: newAggregationId() }) =>
		({ templateId, isDuplicate, correlationParams })
);
export const InitSuccess = createAction(`[${ActionPrefix}] Init Success`);
export const InitFailure = createErrorAction(`[${ActionPrefix}] Init`);

export const formModelActions = {
	...createEntityActions<ITemplateForm>(ActionPrefix, 'FormModel'),
	UpdateEntitySuccess: createTypedAction(
		EntityActionType.UpdateEntitySuccess,
		`[${ActionPrefix}] Update FormModel Success`,
		props<{ entity: ITemplateForm, navigateToList?: boolean, correlationParams?: CorrelationParams }>()
	),
};

export const {
	// LoadEntityRequest: LoadFormModelRequest,
	LoadEntitySuccess: LoadFormModelSuccess,
	LoadEntityFailure: LoadFormModelFailure,

	UpdateEntityRequest: UpdateFormModelRequest,
	UpdateEntityConfirm: UpdateFormModelConfirm,
	UpdateEntitySuccess: UpdateFormModelSuccess,
	UpdateEntityFailure: UpdateFormModelFailure,

	CreateEntityRequest: CreateFormModelRequest,
	// CreateEntitySuccess: CreateFormModelSuccess,
	CreateEntityFailure: CreateFormModelFailure,
} = formModelActions;

export const templateItemActions = createEntityActions<ITemplateItem>(ActionPrefix, 'TemplateItem');
export const {
	DeleteEntityConfirm: DeleteTemplateItemConfirm,
	DeleteEntityRequest: DeleteTemplateItemRequest,
	DeleteEntitySuccess: DeleteTemplateItemSuccess,
	DeleteEntityFailure: DeleteTemplateItemFailure,
} = templateItemActions;

export const CreateFormModelSuccess = createActionWithId(`[${ActionPrefix}] Create Success`);
export const LoadFormModelRequest = createAction(`[${ActionPrefix}] Load Entity Request`,
	props<{ id: number, correlationParams: CorrelationParams }>());

export const UseTemplateRequest = createAction(
	`[${ActionPrefix}] Use Template Request`,
	props<{ template: IMiddleTextEditorTemplate, withProps?: Partial<ITemplateForm> }>()
);

export const UseTemplateSuccess = createAction(
	`[${ActionPrefix}] Use Template Success`,
	props<{ template: ITemplateForm, withProps?: Partial<ITemplateForm> }>()
);

export const UseTemplateFailure = createErrorAction(`[${ActionPrefix}] Use Template`);

export const DuplicateTemplateRequest = createAction(
	`[${ActionPrefix}] Duplicate Request`,
	props<{ id: number, isFormDirty: boolean }>()
);

export const DuplicateTemplateSuccess = createActionWithId(`[${ActionPrefix}] Duplicate Success`);

export const LeaveFormRequest = createActionWithSingleProps<boolean>(`[${ActionPrefix}] Leave Form Request`);

export const LeaveFormSuccess = createAction(`[${ActionPrefix}] Leave Form Success`);
export const DeleteTemplateRedirect = createAction(`[${ActionPrefix}] Delete Template Redirect`);

export const ResetLoaders = createAction(`[${ActionPrefix}] Reset Loaders`);

export const ResetContentConfirm = createAction(
	`[${ActionPrefix}] Reset Template Confirm`,
	props<{ entity: Partial<ITemplateForm>, rollbackProps?: Partial<ITemplateForm> }>()
);
