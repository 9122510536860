
import { createAction, props } from '@ngrx/store';
import { IDunningScenarioForm, IScenarioStep } from 'apps/middle/src/app/dunning-settings-module/models';

import { createEntityActions, createErrorAction, createActionWithSingleProps, createActionWithEntity } from '../functions';
import { CorrelationParams, newAggregationId } from '../models/correlated-actions.model';

const ActionPrefix = 'Dunning Scenario Detail';

// Init request, success and failure + duplicate case
export const InitRequest = createAction(`[${ActionPrefix}] Init Request`,
	(scenarioId: number, isFormDirty = false, correlationParams: CorrelationParams = {correlationId: newAggregationId()}) =>
		( {scenarioId, isFormDirty, correlationParams} )
);
export const InitSuccess = createAction(`[${ActionPrefix}] Init Success`);
export const InitFailure = createErrorAction(`[${ActionPrefix}] Init`);

export const LeaveFormRequest = createActionWithSingleProps<boolean>(`[${ActionPrefix}] Leave Form Request`);
export const LeaveFormSuccess = createAction(`[${ActionPrefix}] Leave Form Success`);

export const formModelActions = createEntityActions<IDunningScenarioForm>(ActionPrefix, 'FormModel');
export const {
	LoadEntitySuccess: LoadFormModelSuccess,
	LoadEntityFailure: LoadFormModelFailure,

	CreateEntityFailure: SaveFormModelFailure,

	UpdateEntityFailure: UpdateFormModelFailure,

	DeleteEntityRequest: DeleteEntityRequest,
	DeleteEntitySuccess: DeleteFormModelSuccess,
	DeleteEntityFailure: DeleteFormModelFailure,
} = formModelActions;

export const LoadFormModelRequest = createAction(
	`[${ActionPrefix}] Load Form Model Request`,
	props<{ id: number, correlationParams?: CorrelationParams, withDefaults?: IDunningScenarioForm }>()
);

export const DuplicateFormModelRequest = createAction(
	`[${ActionPrefix}] Duplicate Form Model Request`,
	props<{ id: number, isFormDirty: boolean }>()
);

export const DuplicateFormModelSuccess = createActionWithSingleProps<number>(`[${ActionPrefix}] Duplicate Form Model Success`);

export const SaveFormModelRequest = createAction(
	`[${ActionPrefix}] Save Form Model Request`,
	props<{ formModel: IDunningScenarioForm, redirect?: boolean }>()
);

export const SaveFormModelSuccess = createAction(
	`[${ActionPrefix}] Save Form Model Success`,
	props<{ formModel: IDunningScenarioForm, redirect?: boolean }>()
);

export const UpdateFormModelRequest = createAction(
	`[${ActionPrefix}] Update Form Model Request`,
	props<{ formModel: IDunningScenarioForm, forceUpdate?: boolean, redirect?: boolean }>()
);

export const UpdateFormModelSuccess = createAction(
	`[${ActionPrefix}] Update Form Model Success`,
	props<{ formModel: IDunningScenarioForm, redirect?: boolean }>()
);

export const UpdateFormModelConfirm = createAction(
	`[${ActionPrefix}] Update Form Model Confirm`,
	props<{ formModel: IDunningScenarioForm, forceUpdate?: boolean, redirect?: boolean }>()
);

export const UpdateFormModelAskDesactivateConfirm = createAction(
	`[${ActionPrefix}] Save Scenario Ask Deactivate Confirm`,
	props<{ payload: { groups: string[], formModel: IDunningScenarioForm } }>()
);

export const DeleteFormModelConfirm = createActionWithSingleProps<IDunningScenarioForm>(`[${ActionPrefix}] Delete Scenario Form Confirm`);
export const DeleteConfirmSuccess = createAction(`[${ActionPrefix}] Delete Scenario Confirm information loaded`);
export const DeleteCanceled = createAction(`[${ActionPrefix}] Delete Scenario Canceled`);

// TODO: check if nullables should be considererd as "options" and not "props"
export const AddScenarioStep = createAction(
	`[${ActionPrefix}] Add Step`,
	props<{ step?: IScenarioStep, formModel?: IDunningScenarioForm }>()
);

export const AddScenarioStepsRequest = createAction(
	`[${ActionPrefix}] Add Step Request`,
	(formModel: IDunningScenarioForm, options: { redirect?: boolean } = {}) =>
		( {formModel, options} )
);

export const AddScenarioStepsSuccess = createAction(
	`[${ActionPrefix}] Add Step Success`,
	props<{ stepIds: number[], thenAddStep?: boolean }>()
);

export const AddScenarioStepFailure = createErrorAction(`[${ActionPrefix}] Add Step`);

export const UpdateScenarioStepRequest = createAction(
	`[${ActionPrefix}] Update Step Request`,
	props<{ step: IScenarioStep, thenAddStep?: boolean }>()
);

export const UpdateScenarioStepSuccess = createActionWithEntity<IScenarioStep>(`[${ActionPrefix}] Update Step Success`);

export const UpdateScenarioStepConfirm = createAction(
	`[${ActionPrefix}] Update Step Confirm`,
	props<{ step: IScenarioStep, thenAddStep?: boolean }>()
);

export const UpdateScenarioStepFailure = createErrorAction(`[${ActionPrefix}] Update Step`);

export const DeleteScenarioStepConfirm = createActionWithEntity<IScenarioStep>(`[${ActionPrefix}] Delete Step Confirm`);
export const DeleteScenarioStepRequest = createActionWithEntity<IScenarioStep>(`[${ActionPrefix}] Delete Step Request`);
export const DeleteScenarioStepSuccess = createActionWithEntity<IScenarioStep>(`[${ActionPrefix}] Delete Step Success`);
export const DeleteScenarioStepFailure = createErrorAction(`[${ActionPrefix}] Delete Step`);

export const SelectScenarioStep = createActionWithSingleProps<number>(`[${ActionPrefix}] Select Step`);

export const MarkStepFormAsDirty = createAction(`[${ActionPrefix}] Step Form Dirty`);
export const MarkStepFormAsClean = createAction(`[${ActionPrefix}] Step Form Clean`);
